/**
 * APIProvider
 *
 * Provides a context that resolves the base API fetcher and parser with URLs
 * prefixed.
 */
import React, { ReactNode } from 'react'

import useApiPrefix from 'hooks/useApiPrefix'

import baseFetch from './baseFetch'
import baseParser from './baseParser'
import context from './context'
import InterceptedResponse from './InterceptedResponse'

const { Provider } = context

export interface Props {
  children: ReactNode
}

export const APIProvider = (props: Props) => {
  const {
    children,
  } = props

  const apiPrefix = useApiPrefix()

  const basicFetcher = (url: RequestInfo, opts?: RequestInit): Promise<Response | InterceptedResponse> => {
    return new Promise(resolve => {
      const prefixedUrl = `${apiPrefix}${url}`
      return resolve(baseFetch(prefixedUrl, opts))
    })
  }

  return (
    <Provider
      value={{
        fetch: basicFetcher,
        parseResponse: baseParser,
      }}
    >
      {children}
    </Provider>
  )
}

export default APIProvider
